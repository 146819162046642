.react-datepicker {
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  background-color: #111529;
  color: rgb(255, 255, 255);
  border: 1px solid var(--light);
  border-radius: 0.3rem;
  display: inline-block;
  align-items: center;
  width: 300px;
  margin-left: 5px;
  box-shadow: 0px 0px 20px #0e0e0e;
}

.react-datepicker__header {
  text-align: center;
  background-color: #080a13;
  border-bottom: 1px solid var(--light);
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  width: 298px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #ff49c2;
  font-size: 0.944rem;
  font-weight: 900;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: rgb(255, 255, 255);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.35rem;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #e434a9;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--light);
  color: rgb(0, 0, 0);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--light);
  color: rgb(0, 0, 0);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--light);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: rgb(126, 126, 126);
}
