.modal {
  font-size: 7pt;
  color: white;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
  opacity: 80%;
  padding: 20px;
  align-content: center;
  margin: auto;
}

.modal > .header {
  width: 90%;
  border-bottom: 1px solid white;
  font-size: 18px;
  text-align: center;
  padding-bottom: 30px;
  margin: 40px auto 0 auto;
}
.modal > .content {
  overflow-y: scroll;
  width: 90%;
  height: 100%;
  text-align: left;
  margin: 20px auto;
  padding-bottom: 200px;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 30px;
  top: 30px;
  font-size: 24px;
  background: var(--light);
  border-radius: 18px;
}

.modal > .close:focus {
  border: none;
  outline: none;
}

.modal > .close:hover {
  background: #b52d87;
}

#termsLink h3:hover {
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
  font-weight: 900;
}

/* .fade-in {
  -webkit-animation: fade-in 0.4s 0.2s;
  animation: fade-in 0.4s 0.2s;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 0.4s ease-out both;
  animation: fade-out 0.4s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal {
  opacity: 80%;
} */
