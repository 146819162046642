@font-face {
  font-family: digital;
  src: url(../assets/fonts/Digital_Dismay.otf);
}

:root {
  --dark: rgb(14, 25, 47);
  --light: rgb(121, 237, 255);
  --select: (#b52d87);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a img.hover {
  display: none;
}
a img.default {
  display: inherit;
}
a:hover img.hover {
  display: inherit;
}
a:hover img.default {
  display: none;
}

#calendarIcon {
  position: absolute;
  margin-left: 10px;
  pointer-events: none;
}

html,
body {
  background-image: linear-gradient(#121212, #121212);
  text-align: center;
  height: 100vh;
  width: 100vw;
  width: auto;
  overflow-x: hidden;
}

#logo {
  top: 30px;
  display: block;
  margin: 30px 30px 10px 30px;
  width: auto;
  height: 130px;
  text-align: center;
}

#mobileLogo {
  position: absolute;
  margin: 20px auto;
  width: 80px;
  z-index: 1;
  left: 0;
  right: 0;
}

.icon {
  display: block;
  margin: 25px 10px;
  width: 30px;
}

.navigation {
  position: fixed;
  width: fit-content;
  margin: 100px auto;
  border-radius: 11px;
  background: #121212;
  box-shadow: 18px 18px 27px #0e0e0e, -18px -18px 60px #0e0e0e;
  z-index: 4;
  opacity: 95%;
}

#profileNav {
  right: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: -20px;
}

#profileNav .icon {
  margin: 20px 15px;
}

#mobileNav {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  z-index: 4;
  visibility: hidden;
}

#navBlock {
  padding: 0 20px;
  margin-left: -25px;
  margin-top: 80px;
  align-items: center;
}

#searchFields {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 120px auto;
  width: fit-content;
  z-index: 1;
  opacity: 90%;
  left: 0;
  right: 0;
}

#searchFields input,
#searchFields button {
  margin-left: 80px;
}

#searchFields input {
  border: none;
  background-color: #121212;
  border-radius: 0px;
}

#searchFields input:hover {
  border: 1.5px solid #e434a9;
  box-shadow: rgba(255, 0, 217, 0.59) 0px 0px 10px;
}

#searchFields button {
  margin-top: 10px;
}

#searchClear {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 40px;
}

#clearFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 40px;
}

#clearFlex h2 {
  margin-bottom: 3px;
}

::placeholder {
  color: var(--light);
  display: inline;
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  text-align: center;
  padding-bottom: 2px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input,
select,
textarea {
  color: var(--light);
  font-family: "Quicksand", sans-serif;
  font-size: 15pt;
  text-align: center;
}

input:focus {
  border-color: none;
  outline: none;
}

#origin,
#destination,
#searchBtn,
#signupBtn,
#loginBtn,
#submitBtn {
  margin: 0 auto 25px auto;
  padding: 10px;
  width: 300px;
  height: 50px;
  background: #212121;
  border-top: 0.5px inset var(--light);
  border-left: 0.5px inset var(--light);
  border-right: 3px inset var(--light);
  border-bottom: 3px inset var(--light);
  border-radius: 5px;
  text-shadow: var(--light) 0 0 1px;
}

#searchBtn {
  width: 250px;
}

#destination {
  margin-bottom: 25px;
}

#destination::placeholder {
  color: rgb(71, 70, 70);
  text-shadow: none;
}

#origin:focus,
#destination:focus,
#searchBtn:focus,
#signupBtn:focus,
#loginBtn:focus,
#submitBtn:focus {
  outline: none;
}

#searchBtn {
  margin: 0;
}

.register {
  background-image: url("../assets/images/runway.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  position: fixed;
  padding-top: 100px;
}
.registerFields {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
}

.register input,
.register button {
  width: 350px;
  height: 50px;
  right: 0;
  left: 0;
  margin: 0 auto 20px auto;
  align-items: left;
  background-color: #11193c;
  opacity: 90%;
  border: none;
}

.register input,
select,
textarea {
  color: rgb(255, 255, 255);
  background-color: #11193c;
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  text-align: left;
  padding-left: 15px;
  border: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 1000px #11193c inset !important;
  -webkit-text-fill-color: white;
  font-family: "Quicksand", sans-serif;
}

.auction input:-webkit-autofill {
  -webkit-text-fill-color: var(--light);
}

#signupBtn,
#loginBtn {
  margin-top: 30px;
  opacity: 100% !important;
}

#submitBtn {
  margin: 0 auto;
}

.enterBid {
  display: flex;
  flex-direction: row;
  margin: 0 25px 25px 25px;
  align-items: center;
  text-align: center;
}

.enterBid input {
  position: relative;
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  text-align: left;
}

.navTab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navTab:hover h1,
#enterBidAlert::placeholder {
  color: #e434a9;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
}

/* Nav links */
h1 {
  color: var(--light);
  display: inline;
  font-family: "Quicksand", sans-serif;
  font-size: 15pt;
  margin: auto 20px;
}

h1:hover {
  color: #e434a9;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
}

.topResultsContainer {
  position: absolute;
  z-index: 2;
  width: 100%;
  right: 0;
  left: 0;
  margin-top: 550px;
  margin-left: 40px;
}

#topResultsHeader h2 {
  text-align: left;
  margin-bottom: 15px;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16pt;
  color: #e434a9;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
}

.topResults {
  box-shadow: 18px 18px 27px #0e0e0e, -18px -18px 27px #161616;
  border-radius: 10px;
  margin-bottom: 200px;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

#result {
  height: 270px;
  border: 2px solid var(--light);
  display: flex;
  flex-direction: column;
}

#result:hover {
  border: 2px solid #e434a9;
  box-shadow: rgba(255, 0, 217, 0.59) 0px 0px 10px;
}

#header1,
#header2 {
  font-family: "Quicksand", sans-serif;
  font-size: 12pt;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: 121212;
  color: rgb(240, 240, 240);
  padding: 20px;
}

#resultsHeader {
  height: 60px;
  background-color: #0f0f0f;
  padding: 20px;
  margin: 15px -20px 20px -20px;
}

#result #header2 {
  position: absolute;
  margin-top: 60px;
  width: calc(45% - 4px);
}

#trip,
#costDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#moreBtn {
  margin-left: 25px;
  margin-right: 0;
}

.planeIcon {
  height: 22px;
  width: auto;
  margin: auto 10px;
}

.plane {
  width: stretch;
  height: 206px;
  object-fit: cover;
  object-position: center;
}

#myPlane .plane {
  height: 250px;
  border-radius: 10px 10px 0 0;
  margin-bottom: -4px;
}

#header1 {
  background: #121212;
  opacity: 95%;
}

#header2,
#auctionBody {
  background: #212121;
  opacity: 80%;
}

.filterFields {
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

#filterContainer {
  background-color: var(--dark);
  opacity: 96%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 18px 18px 27px #0e0e0e, -18px -18px 27px #161616;
}

h2 {
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  color: rgb(240, 240, 240);
}

h3 {
  font-family: "Quicksand", sans-serif;
  font-size: 12pt;
  color: rgb(240, 240, 240);
  opacity: 100%;
}

.auction,
.profile {
  background-image: url("../assets/images/runway.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  position: fixed;
  overflow-y: auto;
  padding-left: 50px;
}

.profileBlock {
  background-color: #0e143e;
  opacity: 90%;
  margin: 20px;
  border-radius: 10px;
  min-height: 80px;
  text-align: start;
  padding-bottom: 10px;
}

.profileOpenBids {
  justify-content: flex-start;
}

.profileOpenBids a h2 {
  font-size: 12pt;
  margin: 5px 0 8px 25px;
}

header,
.profileOpenBids a {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  width: 100%;
}

span {
  color: rgb(152, 152, 152);
  font-size: 8pt;
  text-shadow: none;
  margin-left: 5px;
}

#profileTimer {
  margin-left: -15px;
}

#profileTimer h2 {
  color: #ff44c1;
  text-shadow: rgba(255, 86, 230, 0.755) 0px 0px 11px;
}

#auctionHeader {
  font-family: "Quicksand", sans-serif;
  font-size: 12pt;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgb(240, 240, 240);
  background-color: #0e0e0e;
  opacity: 95%;
  padding: 20px 0 20px 20px;
}

.profileBlockHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}

.auctionDetail,
.profileDetail {
  display: absolute;
  z-index: 2;
  width: 45%;
  background-color: #262626, transparency 50%;
  margin: 80px auto;
  padding: auto 30px;
  border-radius: 10px;
  box-shadow: 12px 12px 40px #36c3ff, -10px -10px 40px #1ef3fe;
  margin-bottom: 200px;
}

.filter {
  position: absolute;
  z-index: 3;
  background-image: none;
  border-radius: 10px;
  width: 45%;
  margin: 100px auto;
  right: 0;
  left: 0;
}

.filter #filterContainer {
  margin-top: -20px;
  margin-bottom: 200px;
}

.filterRow input {
  height: 50px;
  width: 300px;
  margin: 5px;
  color: var(--light);
  background-color: #1c223c;
  border: none;
}

#filterResultsError {
  margin: 40px auto;
}

#filterResultsError h2 {
  color: #ff44c1;
  text-shadow: rgba(255, 86, 230, 0.755) 0px 0px 11px;
}

input.calendar {
  cursor: pointer;
  font-size: 12pt;
  color: white;
  text-transform: uppercase;
}

.filterRow .filterColumn {
  margin-left: 0px;
}

.filterColumn input {
  background-color: #1c223c;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 16pt;
  margin: 0 10px 0 -25px;
  filter: invert(1);
}

.filterBody {
  display: flex;
  flex-direction: row;
  margin: 20px;
  margin-bottom: 30px;
  justify-content: center;
}

.filterRow,
.auctionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12pt;
}

.filterRow h2,
.profileDetail h2 {
  font-size: 16pt;
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 4px;
}

.filterRow h2 {
  margin: 20px 20px 30px 20px;
}

.profileDetail .profileOpenBids h2 {
  color: rgb(121, 237, 255);
  text-shadow: var(--light) 0 0 4px;
}

.profileDetail .profileOpenBids h2:hover {
  color: rgb(255, 4, 217);
  text-shadow: rgb(255, 4, 217) 0 0 4px;
  font-weight: 900;
}

#filteredResults #result #header2 {
  width: calc(100% - 44px);
}

.profileDetail h2 {
  text-align: left;
  margin: 30px 30px 10px 30px;
}

.filterColumn h5 {
  margin-top: 6px;
  margin-bottom: 6px;
}

#countdown {
  background-color: black;
  font-size: 26pt;
  height: 60px;
  width: 170px;
  color: #e434a9;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 4px;
  font-weight: 900;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  font-family: digital;
  margin-left: 5px;
}

#timerBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#timerHeader {
  position: absolute;
  margin-top: -35px;
  margin-left: -15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 3px;
}

#timerHeader h6 {
  margin-left: 27px;
  padding-right: 10px;
  color: rgb(140, 140, 140);
  font-weight: 900;
}

#timerBlock h2 {
  display: inline;
}

#timerStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#auctionBody {
  font-family: "Quicksand", sans-serif;
  font-size: 12pt;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  background-color: 121212;
  color: rgb(240, 240, 240);
  padding: 20px;
  align-items: flex-start;
}

label {
  color: rgb(240, 240, 240);
  font-family: "Quicksand", sans-serif;
}

.auctionBodyColumn,
.filterColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
}

.filterColumn {
  margin: 10px;
  text-align: right;
}

.profileColumn {
  text-align: left;
  margin: 20px 5px 0 0;
}

#profileLinks {
  margin: 10px 10px 0 2px;
}

.profileColumn h3 {
  margin: 3px;
  font-size: 11pt;
}

.profileColumn h5 {
  margin: 3px;
  color: rgb(152, 152, 152);
  opacity: 70%;
}

.filterColumn form select {
  width: 100px;
  margin: 5px auto;
  background-color: #1c223c;
  border: none;
  cursor: pointer;
  color: #a0f9fe;
}

.filterColumn form select:focus {
  outline: none !important;
}

#auctionBody h3 {
  margin: 4px 7px 4px 0px;
  color: var(--light);
  text-shadow: var(--light) 0px 0px 1px;
}

.auctionBodyColumn h3 {
  color: rgb(240, 240, 240);
  opacity: 100% !important;
  margin-right: 3px;
}

#bidHeader {
  font-family: "Quicksand", sans-serif;
  font-size: 12pt;
  height: 40px;
  background-color: #0e0e0e;
  color: rgb(240, 240, 240);
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 5px;
}

.bidHistory {
  margin-bottom: 20px;
  background-color: #161616;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 140px;
}

.otherBid {
  margin: 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.auction input {
  width: 160px;
  height: 50px;
  right: 0;
  left: 0;
  border-radius: 5px;
  border: 1px solid var(--light);
  padding-right: 10px;
}

.auction input,
select,
textarea {
  color: var(--light);
  font-family: "Quicksand", sans-serif;
  font-size: 14pt;
  text-align: center;
  background-color: #191f35;
}

.termsContainer,
.leadingBidContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

#leadBidTitle {
  margin-bottom: 5px;
}

.termsContainer {
  position: relative;
}

.termsContainer input,
.filterColumn input {
  display: none;
}

.termsContainer label,
.filterColumn label {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.termsContainer label:before,
.filterColumn label:before {
  display: flex;
  content: " ";
  height: 15px;
  width: 15px;
  border: 1px solid var(--light);
  border-radius: 3px;
  background-color: #38373f;
  margin-right: 10px;
}

.termsContainer label:after,
.filterColumn label:after {
  position: absolute;
  content: "\2713";
  height: 15px;
  width: 15px;
  color: #e434a9;
  background-color: #312f3c;
  border-radius: 3px;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.termsContainer input:checked + label:after,
.filterColumn input:checked + label:after {
  transform: scale(1);
  border: 1px solid #e434a9;
}

.filterColumn label {
  font-size: 11pt;
  margin-left: 20px;
}

.leadingBidContainer {
  justify-content: space-between;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.serviceContainer #addServiceLink h3:hover {
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 4px;
  font-weight: 900;
}

li {
  font-size: 10px;
  margin-top: 8px;
}

#leadingBid {
  background-color: #323234;
  padding: 8px;
  margin-left: 8px;
  margin-bottom: 6px;
  width: 150px;
  align-items: right;
  text-align: end;
}

#leadingBid h2 {
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
  font-size: 16pt;
}

#addService,
#addPremiumBar,
#addWiFi {
  height: 17px;
  width: 17px;
  margin: 7px;
}

.auctionMessage {
  margin: 20px auto;
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 21px;
}

h5 {
  font-family: "Quicksand", sans-serif;
  font-size: 11pt;
  color: rgb(240, 240, 240);
}

h6 {
  font-family: "Quicksand", sans-serif;
  font-size: 8pt;
  color: var(--light);
  padding: 2px;
}

#watchIcon,
#watchIcon2 {
  margin: 0px 5px 0px 5px;
  width: 45px;
}

.watching {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

#userImg {
  width: 110px;
  opacity: 70%;
  margin: auto 20px;
}

.profileBlockHeader img {
  height: 25px;
  margin: 8px 5px 5px 0;
  opacity: 70%;
}

.profileBlockHeader h3 {
  color: rgb(152, 152, 152);
}

#addUserImg,
#addUserImg2 {
  position: absolute;
  margin-left: 60px;
  margin-top: 27px;
  z-index: 4;
}

.watchOption {
  margin: 5px 15px 15px 15px;
}

#watchOption {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.watchOption h2 {
  margin-left: 10px;
  font-size: 12pt;
  text-align: left;
}

#deleteWatch,
#deleteWatch2 {
  transform: rotate(45deg);
  width: 25px;
}

#clearSearch,
#clearSearch2 {
  transform: rotate(45deg);
  width: 30px;
  margin: 3px;
}

.register a {
  text-decoration: none;
  color: var(--light);
  margin-left: 3px;
}

.register a:hover,
h6:hover {
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 4px;
  font-weight: 900;
}

.error-text {
  position: relative;
  margin: -40px auto 0 auto;
  color: #ff4dc4;
  text-shadow: rgba(255, 0, 217, 0.59) 0px 0px 4px;
}

.register ::placeholder {
  color: rgb(104, 104, 104);
  text-align: left;
  font-size: 12pt;
}

.header h2 {
  color: var(--light);
  text-shadow: var(--light) 0px 0px 10px;
}

.content h6 {
  margin: 5px 0px -10px -2px;
}

.content h6:hover {
  color: var(--light);
  text-shadow: none;
}

@media screen and (min-width: 2300px) {
  #searchFields {
    margin-left: 32%;
  }

  #result {
    height: 350px;
  }

  .plane {
    height: 286px;
  }

  #myPlane .plane {
    height: 330px;
  }

  .auctionRow,
  h3,
  h5,
  .serviceContainer,
  #bidHeader {
    font-size: 14pt;
  }

  li {
    font-size: 9pt;
  }
}

@media screen and (min-width: 1900px) {
  .topResults,
  .auctionDetail,
  .profileDetail,
  .filter,
  #topResultsHeader h2 {
    width: 40%;
    margin-left: 450px;
  }

  #result #header2 {
    width: calc(40% - 4px);
  }

  #searchFields {
    margin-left: 35%;
  }

  #searchFields input,
  #searchFields button {
    margin-left: 0;
  }
}

@media screen and (max-width: 1300px) {
  #logo {
    display: none;
  }

  h1 {
    display: none;
  }

  .enterBid h1 {
    display: inline;
  }

  button h1 {
    display: inline;
  }

  #origin,
  #destination,
  #loginBtn,
  #signupBtn {
    width: 275px;
  }

  #searchBtn {
    width: 200px;
  }

  .topResults,
  .auctionDetail,
  .profileDetail,
  .filter,
  #topResultsHeader h2 {
    width: 60%;
  }

  #result #header2 {
    width: calc(60% - 4px);
  }

  #searchFields input,
  #searchFields button {
    margin-left: auto;
    margin-right: auto;
  }

  .topResultsContainer {
    margin-left: 0px;
  }
}

@media screen and (max-width: 900px) {
  #navBlock {
    margin: 0;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    -ms-flex-align: center;
    justify-content: space-around;
    border-radius: 0;
    bottom: 0;
    opacity: 100%;
    z-index: 4;
  }

  .auction,
  .profile {
    padding-left: 0;
  }

  #navBlock.navigation .search {
    margin-left: -25px;
  }

  #navBlock.navigation .settings {
    margin-right: 25px;
  }

  .auctionRow {
    font-size: 10pt;
  }

  #mobileNav {
    visibility: visible;
  }

  .topResults,
  .auctionDetail,
  .profileDetail,
  .filter,
  #topResultsHeader h2 {
    width: 93%;
  }

  #result #header2 {
    margin-top: 60px;
    width: calc(93% - 4px);
  }

  #myPlane .plane {
    height: 210px;
  }

  h2 {
    font-size: 12pt;
  }

  #moreBtn {
    margin-left: 15px;
  }

  .planeIcon {
    height: 18px;
    margin: auto 5px;
  }

  #countdown {
    font-size: 25pt;
    width: 170px;
    align-items: center;
    margin-left: 10px;
  }

  #timerHeader {
    margin-left: -8px;
    padding-right: 0px;
  }

  .leadingBidContainer {
    margin-bottom: 5px;
  }

  #leadingBid {
    width: 90px;
    margin-left: 0;
  }

  .termsContainer label:before,
  .termsContainer label:after,
  .filterColumn label:before,
  .filterColumn label:after {
    height: 10px;
    width: 10px;
    margin-right: 4px;
  }

  .serviceContainer {
    font-size: 10pt;
    margin-bottom: 5px;
  }

  .termsContainer {
    font-size: 10pt;
    margin-bottom: 5px;
  }

  li {
    font-size: 6.5pt;
  }

  .watchOption {
    justify-content: center;
  }

  .watchOption h2 {
    margin-left: 0px;
    font-size: 11pt;
  }

  h3,
  h5 {
    font-size: 10pt;
  }

  #clearSearch,
  #clearSearch2 {
    width: 25px;
  }

  #watchIcon,
  #watchIcon2 {
    width: 35px;
  }

  #forceFlex {
    justify-content: center;
  }

  .bidHistory {
    height: 125px;
  }
}

@media screen and (max-width: 505px) {
  .topResultsContainer {
    margin-top: 500px;
  }

  .register input {
    width: 300px;
  }

  .filterFields {
    height: 100px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  #myPlane {
    height: 160px;
  }

  .plane {
    height: 167px;
  }

  #filterAircraft select,
  #filterOperator select,
  #filterCabinSize select {
    font-size: 10pt;
    width: 80px;
  }

  #filterContainer {
    padding: 0;
  }

  .filterBody {
    margin: 5px;
    justify-content: center;
    margin-bottom: 10px;
  }

  #addService,
  #addPremiumBar,
  #addWiFi {
    height: 15px;
    width: 15px;
    margin: 5px auto;
  }

  #timerBlock h2 {
    display: none;
  }

  #auctionBody {
    padding: 5px;
  }

  #result {
    height: 220px;
  }

  #resultsHeader {
    margin: 0;
    margin-top: 10px;
  }

  #resultsHeader h2 {
    padding-top: -15px;
  }

  #header1,
  #header2 {
    height: 50px;
  }

  #result #header2 {
    margin-top: 50px;
  }

  .auctionRow {
    font-size: 8pt;
  }

  #userImg {
    width: 70px;
    margin: 0 8px 0 0;
  }

  #addUserImg,
  #addUserImg2 {
    width: 20px;
    position: absolute;
    margin-left: 25px;
    margin-top: 18px;
    z-index: 4;
  }

  .profileOpenBids a h2 {
    font-size: 10pt;
    margin-left: 20px;
  }

  #auctionHeader {
    padding: 20px 0 20px 15px;
  }

  .profileColumn h3 {
    font-size: 8pt;
    margin: 4px auto;
  }

  #filteredResults #result #header2 {
    width: calc(100% - 4px);
  }

  #navBlock.navigation .search {
    margin-left: 0;
  }

  #navBlock.navigation .settings {
    margin-right: 15px;
  }

  #profileLinks h6 {
    font-size: 8pt;
  }

  #loginBtn,
  #signupBtn,
  #submitBtn,
  #searchBtn,
  #origin,
  #destination {
    width: 220px;
    margin-bottom: 10px;
    padding: 8px;
  }

  .auction input::placeholder,
  #submitBtn h1,
  #searchBtn h1,
  #loginBtn h1,
  #signupBtn h1 {
    font-size: 12pt;
  }

  .bidHistory {
    margin-bottom: 10px;
  }

  .enterBid input {
    height: 45px;
    width: 110px;
    margin-right: 10px;
  }

  .enterBid {
    display: flex;
    flex-direction: row;
    margin: -5px 15px 10px 15px;
    align-items: baseline;
  }

  #leadingBid {
    margin-right: -5px;
    margin-top: -5px;
  }

  .auctionBodyColumn {
    margin-left: 10px;
  }

  .auctionBodyColumn h3 {
    font-size: 9pt;
  }

  .registerFields {
    margin-top: 20px;
  }

  .register input,
  .register button {
    height: 45px;
    margin: 10px auto;
    border-radius: 5px;
  }

  #submitBtn,
  #signupBtn,
  #loginBtn {
    height: 42px;
    margin-top: 20px;
  }

  #submitBtn {
    margin: 5px auto 10px auto;
  }

  #deleteWatch,
  #deleteWatch2 {
    width: 18px;
  }

  #clearSearch,
  #clearSearch2 {
    width: 20px;
  }

  #searchClear {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }

  #clearFlex {
    margin: 5px 0;
  }

  li {
    margin-top: 3px;
  }

  #bidHeader {
    font-size: 9pt;
    padding-left: 15px;
    padding-top: 12px;
    margin-bottom: 0px;
  }

  h5,
  h3,
  .serviceContainer {
    font-size: 9pt;
  }

  .serviceContainer {
    margin-bottom: 0;
  }

  .auctionMessage {
    margin: auto;
  }

  #profileWatchIcon {
    width: 30px;
    height: 30px;
  }

  .profileBlockHeader h3 {
    margin-left: 5px;
  }

  label {
    font-size: 9pt;
  }

  .filterColumn label {
    font-size: 9pt;
  }

  #timerHeader h6 {
    margin-left: 26px;
  }

  .auction input {
    margin-left: 8px;
    height: 40px;
  }
}
